/* global google */
import React from "react";
import { createCustomEqual } from "fast-equals";

const useDeepCompareEffectForMaps = (callback, dependencies) => {
  const isLatLngLiteral = (obj) =>
    obj != null &&
    typeof obj === "object" &&
    Number.isFinite(obj.lat) &&
    Number.isFinite(obj.lng);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }
    return deepEqual(a, b);
  });

  function useDeepCompareMemoize(value) {
    const ref = React.useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
      ref.current = value;
    }

    return ref.current;
  }

  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

export default useDeepCompareEffectForMaps;
