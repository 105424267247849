import React from "react";

const Logo = ({width, height, className}) => {
  return (
    <svg
      width={width ?? "37"}
      height={height ?? "34"}
      className={className}
      viewBox="0 0 37 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.361328" width="6.52945" height="33" fill="#017BF1" />
      <rect
        x="8.70605"
        y="0.361328"
        width="8.70593"
        height="22"
        fill="#017BF1"
      />
      <rect
        x="19.5879"
        y="0.361328"
        width="17.4119"
        height="22"
        fill="#017BF1"
      />
      <rect
        x="8.70605"
        y="24.5615"
        width="28.2943"
        height="8.8"
        fill="#017BF1"
      />
    </svg>
  );
};

export default Logo;
