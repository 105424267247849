import React from "react";
import * as LogoStyles from "./WhiteBigLogo.module.scss";
const WhiteBigLogo = ({ width, height, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 3382.1 362"
      style={{ enableBackground: "new 0 0 3382.1 362" }}
      xmlSpace="preserve"
    >
      <path
        className={LogoStyles.st0}
        d="M73,0c0,2.3-0.1,4.6-0.1,7c0,115.8,0,231.6,0,347.5c0,2.3,0,4.6,0,7.6c-24.7,0-48.8,0-72.9,0
      C0,241.3,0,120.7,0,0C24.4,0,48.7,0,73,0z"
      />
      <path
        className={LogoStyles.st0}
        d="M442.3,249.9c-66.5,0-132.6,0-198.9,0c0-82.8,0-165.3,0-248.2c66.2,0,132.4,0,198.9,0
      C442.3,84.3,442.3,167,442.3,249.9z"
      />
      <path
        className={LogoStyles.st0}
        d="M3083.6,81.6c16.3-7.8,31.3-15.9,47.1-22.4c41.5-17.4,85-22.3,129.6-18.4c22,1.9,43.5,5.9,63.7,15.3
      c12,5.6,22.9,12.8,31.2,23.5c16.7,21.4,15.2,52.6-4.5,71.3c-7.5,7-17.2,11.7-25.9,17.4c-1.2,0.8-2.6,1.3-5.2,2.5
      c2.6,0.9,4.3,1.3,5.7,2c10.3,5,21.4,8.8,30.6,15.3c34.3,24.1,34.8,70.9,1.6,96.5c-17.7,13.6-38.2,21-59.7,25.1
      c-48.7,9.4-97.6,10.8-146.3,0.2c-32.9-7.1-61.7-22.5-86.8-44.9c-0.9-0.8-1.9-1.3-3.3-2.3c11.2-9.9,21.6-19.2,32.3-28.1
      c0.9-0.7,3.9,0.1,5.3,1.1c23.1,17.6,48.7,29.8,77.1,36.1c26.8,6,53.8,6.8,80.9,3.8c13-1.4,26-4,38.5-7.5c7.2-2,13.9-6.2,20.2-10.5
      c7.3-5,11.3-12.5,12.4-21.4c1.6-12-3.4-21-13.4-27.4c-10.8-7-23-10.2-35.5-12.6c-29.3-5.6-59-5.4-88.6-5.6c-2.2,0-4.3-0.1-6.5-0.1
      c-0.5,0-0.9-0.3-1.7-0.6c0-11.4,0-22.8,0-34.7c1.9-0.1,3.9-0.3,5.8-0.3c18.8-0.1,37.7,0.1,56.5-0.4c15-0.4,30-2,43.9-7.9
      c6.4-2.8,13-6.5,17.9-11.3c13.3-13.3,10.4-33.6-5.5-43.5c-11.6-7.2-24.8-9.7-38-11c-39.1-3.9-77.5-1.4-114.5,12.7
      c-10.8,4.1-21.3,9.3-31.8,14.4c-2.7,1.3-4.2,1.3-6.4-0.8C3102,98.9,3093.4,90.9,3083.6,81.6z"
      />
      <path
        className={LogoStyles.st0}
        d="M592.5,214.3c0,34.5,0,68.4,0,102.5c-15.2,0-30,0-45.2,0c0-89.5,0-178.9,0-268.6c1.2-0.1,2.4-0.4,3.5-0.4
      c75.8,0.1,151.6-0.4,227.4,0.6c26.9,0.3,50.3,11.1,66.7,33.6c13.5,18.4,16.4,39.6,14.1,61.7c-4.4,42.5-33.4,61.5-66.6,67.5
      c-12.2,2.2-24.8,3-37.2,3.1c-51.8,0.3-103.6,0.1-155.4,0.1C597.7,214.3,595.5,214.3,592.5,214.3z M592.8,174.3
      c2,0.1,3.7,0.3,5.3,0.3c53.3,0,106.6,0.1,159.9-0.1c7.1,0,14.3-0.6,21.3-2c15.8-3,28.1-10.7,32.1-27.4c1.4-5.9,1.8-12.2,1.3-18.3
      c-1.2-17.2-9.9-29.6-26.6-34.9c-8-2.6-16.6-4.2-24.9-4.3c-54.8-0.4-109.6-0.2-164.4-0.2c-1.3,0-2.6,0.2-4.1,0.3
      C592.8,116.6,592.8,145.2,592.8,174.3z"
      />
      <path
        className={LogoStyles.st0}
        d="M1390.9,18.2c68.2,97.9,129,199.2,194.7,298.6c-14.7,0-28.1-0.8-41.3,0.3c-10.5,0.9-16.5-2.6-21.5-11.9
      c-7.5-14-16.8-27.1-25.1-40.8c-1.9-3-3.9-4.3-7.5-4.3c-65.5,0.1-131,0.1-196.4,0c-3.4,0-5.4,1.1-7.2,4c-10,16.3-20.2,32.4-30.3,48.7
      c-1.7,2.8-3.6,4.1-7.1,4.1c-16.3-0.2-32.6-0.1-50.3-0.1C1263.1,217.1,1326.6,118.2,1390.9,18.2z M1470.9,223.3
      c-26.3-41.2-52-81.5-78.3-122.6c-26.6,41.2-52.7,81.5-79.2,122.6C1366.3,223.3,1417.9,223.3,1470.9,223.3z"
      />
      <path
        className={LogoStyles.st0}
        d="M2136.6,18.8c68.1,97.8,128.8,198.9,194.6,298.4c-3.4,0-5.3,0-7.2,0c-13.8,0-27.7-0.3-41.5,0.1
      c-5.1,0.2-7.7-1.7-10.2-5.8c-9.4-15.6-19.3-31-28.9-46.6c-1.8-2.9-3.8-3.9-7.2-3.9c-65.6,0.1-131.3,0.1-196.9,0
      c-3.4,0-5.4,1.1-7.1,4c-10,16.3-20.1,32.5-30.4,48.6c-0.9,1.5-2.8,3.4-4.2,3.4c-17.1,0.2-34.3,0.2-52.8,0.2
      C2008.8,217.6,2072.3,118.8,2136.6,18.8z M2059.3,223.5c52.8,0,104.4,0,157,0c-26.2-41-51.8-81.2-78-122.2
      C2111.7,142.4,2085.7,182.6,2059.3,223.5z"
      />
      <path
        className={LogoStyles.st0}
        d="M1935,277c0,13.7,0,26.5,0,39.7c-110.1,0-220.1,0-330.9,0c79.1-72.3,149.7-152.4,225.5-227.4
      c-0.2-0.5-0.4-0.9-0.6-1.4c-69,0-137.9,0-207.8,0c0-3.6,0-6.5,0-9.4c0-8.6,0.1-17.3-0.1-25.9c-0.1-3.7,1.2-5,4.9-4.8
      c5.2,0.3,10.3,0.1,15.5,0.1c93.3,0.1,186.6,0.2,279.9,0.2c1.8,0,3.6,0,5.2,0c-73.9,75.9-147.9,151.9-221.8,227.8
      c0.2,0.4,0.4,0.8,0.6,1.2C1781.7,277,1858.1,277,1935,277z"
      />
      <path
        className={LogoStyles.st0}
        d="M2474.1,121.6c-9.4-9.9-18.5-19.6-27.9-29.5c1.7-1.3,3.1-2.5,4.5-3.6c31.3-23.3,65.9-38.8,104.9-43.1
      c35.5-3.9,70.3-1.6,102.2,16.5c19.2,10.9,34.3,25.7,40.6,47.7c8.4,29.4-1.2,56.9-26.2,74.6c-16.5,11.6-35.4,17.1-54.6,22.1
      c-25.8,6.8-51.8,13.4-77,22.2c-22,7.7-39.7,22.1-51.4,42.9c-0.9,1.7-1.7,3.5-2.8,5.9c74.2,0,147.6,0,221.3,0c0,13.3,0,26.2,0,39.4
      c-91.3,0-182.5,0-273.9,0c2-39.7,15.8-73.4,46.5-99.1c22.2-18.6,48.4-29.4,76.1-37c21.6-6,43.1-12.3,64.5-19.1
      c6.5-2.1,12.9-5.6,18.5-9.6c17.6-12.7,18.6-35.6,2.6-50.3c-10.3-9.5-23-14-36.5-16c-46.8-6.9-89.1,4.3-126.9,32.6
      C2477.2,119.3,2475.9,120.3,2474.1,121.6z"
      />
      <path
        className={LogoStyles.st0}
        d="M107.5,361.4c0-26.6,0-53,0-79.7c111.6,0,222.9,0,334.7,0c0,26.5,0,52.9,0,79.7
      C330.8,361.4,219.4,361.4,107.5,361.4z"
      />
      <path
        className={LogoStyles.st0}
        d="M107.5,1.6c33.1,0,65.7,0,98.7,0c0,82.7,0,165.2,0,248.1c-32.7,0-65.5,0-98.7,0
      C107.5,167.1,107.5,84.6,107.5,1.6z"
      />
      <path
        className={LogoStyles.st0}
        d="M2977.6,87.6c-72.5,0-145,0-217.8,0c0-13.3,0-26.1,0-39.4c1.7-0.1,3.4-0.4,5.1-0.4c86.6,0,173.2,0,259.8-0.1
      c4.2,0,5.9,1,5.8,5.5c-0.3,10.1,0.1,20.3-0.2,30.4c-0.1,2.4-1,5.3-2.5,7.2c-57,74.5-114.1,148.8-171.3,223.1c-1.1,1.5-3.4,3-5.1,3
      c-15.3,0.2-30.6,0.1-46,0.1c-0.4,0-0.9-0.3-1.9-0.6c58.3-76,116.5-151.8,174.6-227.5C2978.1,88.5,2977.9,88,2977.6,87.6z"
      />
      <path
        className={LogoStyles.st0}
        d="M1178.2,316.9c-87.8,0-175,0-262.7,0c0-89.7,0-179.1,0-268.8c14.7,0,29.1,0,43.9,0c0,76.4,0,152.4,0,229.1
      c73.1,0,145.8,0,218.8,0C1178.2,290.7,1178.2,303.6,1178.2,316.9z"
      />
    </svg>
  );
};

export default WhiteBigLogo;
