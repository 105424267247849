import tw, { styled } from "twin.macro";

const Container1440 = tw.div`
    max-w-[1440px] w-full ml-auto mr-auto
`;

const Container1440Responsive = tw(Container1440)`
    px-4 md:px-[50px]  lg:px-[60px] xl:px-[100px]
`;

export { Container1440, Container1440Responsive };
