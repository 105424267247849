import React from "react";
import { FooterActiveContent } from "./FooterContact.styled";

const FooterContact = ({ letter, text, dark, letterClass, textClass }) => {
  const lightContent = (
    <div className="flex flex-row">
      <p
        className={letterClass ?? "typo-footer text-blues-dark-blue font-bold"}
      >
        {letter}.&nbsp;
      </p>
      <FooterActiveContent
        className={textClass ?? "typo-footer text-neutral-grey"}
        dangerouslySetInnerHTML={{ __html: text }}
      ></FooterActiveContent>
    </div>
  );

  const darkContent = (
    <div className="flex flex-row">
      <p className={letterClass ?? "typo-footer text-neutral-white font-bold"}>
        {letter}.&nbsp;
      </p>
      <p
        className={textClass ?? "typo-footer text-neutral-white"}
        dangerouslySetInnerHTML={{ __html: text }}
      ></p>
    </div>
  );
  return <>{dark ? darkContent : lightContent}</>;
};

export default FooterContact;
