import { useEffect, useState } from "react";
import { isBrowserPlatform } from "../helpers";

export function useIsBrowser() {
  const [isBrowser, setIsBrowser] = useState();

  useEffect(() => {
    setIsBrowser(isBrowserPlatform());
  }, []);

  return isBrowser;
}