import React, { useEffect, useRef, useState } from "react";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import classNames from "classnames";

import { Link } from "gatsby";
import MixBigLogo from "../components/common/logo/MixBigLogo";
import { Container1440Responsive } from "./common/global.styled";
import { useScrollTop, useHaveScroll } from "../hooks";
import WhiteBigLogo from "./common/logo/WhiteBigLogo";
import Logo from "./common/logo/Logo";

const isActive = ({ isCurrent }) => {
  return isCurrent
    ? { className: "nav-link active " }
    : { className: "nav-link " };
};

const ExactNavLink = (props) => (
  <Link partiallyActive={true} getProps={isActive} {...props} />
);

const Navbar = () => {
  const btn = useRef();
  const menu = useRef();
  const [openedSidebar, setOpenedSidebar] = useState(false);
  const [firstRender, setFirtRender] = useState(true);
  const [showBlueBackground, setShowBlueBackground] = useState(false);

  const breakpoints = useBreakpoint();
  const scrollTop = useScrollTop();
  const haveScroll = useHaveScroll();

  useEffect(() => {
    setFirtRender(false);
  }, []);

  useEffect(() => {
    if (openedSidebar) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "";
      setShowBlueBackground(false);
    }
  }, [openedSidebar]);

  useEffect(() => {
    if (!breakpoints.sm) {
      setOpenedSidebar(false);
      setShowBlueBackground(false);
    }
  }, [breakpoints.sm]);

  useEffect(() => {
    if (showBlueBackground) {
      document.body.classList.add("show-mobile-menu");
    } else {
      document.body.classList.remove("show-mobile-menu");
    }
  }, [showBlueBackground]);

  const toggleMenu = () => {
    setOpenedSidebar(!openedSidebar);
  };

  const showBackground = () => {
    setTimeout(() => {
      if (openedSidebar) {
        setShowBlueBackground(true);
      }
    }, 500);
  };

  const showNavbar =
    firstRender || (haveScroll && !breakpoints.sm && scrollTop < 80)
      ? false
      : true;

  const navbarHeight = showNavbar ? (breakpoints.sm ? "auto" : "66px") : "0px";
  return (
    <>
      <header
        className="animated-navbar fixed top-0 w-full"
        style={{
          zIndex: 10000,
          height: navbarHeight,
        }}
      >
        <nav className="bg-blues-dark-blue shadow-lg border-b-[1px] border-neutral-white sticky z-50 top-0">
          <Container1440Responsive>
            <div className="flex justify-between">
              <div className="flex space-x-7">
                <Link
                  to="/"
                  className="flex items-center pt-8 pb-8 md:py-4 pr-2"
                >
                  {breakpoints.sm ? (
                    <MixBigLogo width="132" height="14" className="mr-2" />
                  ) : (
                    <Logo width="37" height="33" className="mr-2" />
                  )}
                </Link>
              </div>
              <div className=" desktop-links hidden md:flex items-center space-x-1 uppercase text-neutral-white">
                <ExactNavLink to="/spaces/" href="#" className="">
                  Spaces
                </ExactNavLink>
                <ExactNavLink to="/contact/" href="#" className="">
                  Contact
                </ExactNavLink>
              </div>
              <div className="md:hidden flex items-center">
                <button
                  ref={btn}
                  onClick={() => toggleMenu()}
                  className="outline-none menu-button"
                >
                  <svg
                    width="25"
                    height="14"
                    viewBox="0 0 25 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.931641 13.9369H24.9316V11.6773H0.931641V13.9369ZM0.931641 8.28776H24.9316V6.02809H0.931641V8.28776ZM0.931641 0.378906V2.63858H24.9316V0.378906H0.931641Z"
                      fill="#C9D1E4"
                    />
                  </svg>
                </button>
              </div>

              {/*       <Slide right duration={500} when={openedSidebar}>
                <div
                  ref={menu}
                  className={`fixed md:hidden mobile-menu bg-blues-light-blue ${
                    !openedSidebar && "pointer-events-none"
                  }`}
                >
                  <div className="bg-blues-light-blue border-b-[1px] border-neutral-white flex justify-between">
                    <div className="flex space-x-7">
                      <Link
                        to="/"
                        className="flex items-center  pt-8 pb-8 pr-2 pl-4"
                      >
                        <WhiteBigLogo
                          width="132"
                          height="14"
                          className="mr-2"
                        />
                      </Link>
                    </div>
                    <div className="md:hidden flex items-center pr-4">
                      <button
                        onClick={() => toggleMenu()}
                        className="outline-none menu-button"
                      >
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.9316 6.41L18.5216 5L12.9316 10.59L7.34164 5L5.93164 6.41L11.5216 12L5.93164 17.59L7.34164 19L12.9316 13.41L18.5216 19L19.9316 17.59L14.3416 12L19.9316 6.41Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <Fade right cascade when={openedSidebar} duration={500}>
                    <ul className="uppercase pt-[76px] px-4">
                      <li>
                        <ExactNavLink to="/" href="#" className="">
                          Home
                        </ExactNavLink>
                      </li>
                      <li>
                        <ExactNavLink to="/spaces/" href="#" className="">
                          Spaces
                        </ExactNavLink>
                      </li>
                      <li>
                        <ExactNavLink to="/contact/" href="#" className="">
                          Contact
                        </ExactNavLink>
                      </li>
                    </ul>
                  </Fade>
                  <div className="bg-blues-light-blue border-t-[1px] border-neutral-white pb-[40px] px-[32px] pt-[16px]">
                    <div className="flex flex-row mb-4">
                      <p className="typo-footer text-base text-blues-light font-bold">
                        A.&nbsp;
                      </p>
                      <p className="typo-footer text-base text-blues-light">
                        <a
                          target="_blank"
                          href="https://goo.gl/maps/eGgrz2zksmtG5Y4GA"
                        >
                          273 Ponce de León Avenue, Hato Rey
                        </a>
                      </p>
                    </div>
                    <div className="flex flex-row">
                      <p className="typo-footer text-base text-blues-light font-bold">
                        T.&nbsp;
                      </p>
                      <p className="typo-footer text-base text-blues-light">
                        <a href="tel:787-622-9600">787 622 9600</a>
                      </p>
                    </div>
                  </div>
                </div>
              </Slide> */}
            </div>
          </Container1440Responsive>
        </nav>
      </header>
      <Slide
        right
        duration={500}
        when={openedSidebar}
        mountOnEnter
        unmountOnExit
        onReveal={() => showBackground()}
      >
        <div
          ref={menu}
          className={classNames(["md:hidden mobile-menu bg-blues-light-blue"], {
            "pointer-events-none": !openedSidebar,
          })}
        >
          <div className="bg-blues-light-blue border-b-[1px] border-neutral-white flex justify-between">
            <div className="flex space-x-7">
              <Link
                to="/"
                className="flex items-center  pt-8 pb-8 pr-2 pl-4 pointer-events-auto"
              >
                <WhiteBigLogo width="132" height="14" className="mr-2" />
              </Link>
            </div>
            <div className="md:hidden flex items-center pr-4">
              <button
                onClick={() => toggleMenu()}
                className="outline-none menu-button pointer-events-auto"
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.9316 6.41L18.5216 5L12.9316 10.59L7.34164 5L5.93164 6.41L11.5216 12L5.93164 17.59L7.34164 19L12.9316 13.41L18.5216 19L19.9316 17.59L14.3416 12L19.9316 6.41Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
          <Fade right cascade when={openedSidebar} duration={500}>
            <ul className="uppercase pt-[76px] px-4">
              <li>
                <ExactNavLink to="/" href="#" className="">
                  Home
                </ExactNavLink>
              </li>
              <li>
                <ExactNavLink to="/spaces/" href="#" className="">
                  Spaces
                </ExactNavLink>
              </li>
              <li>
                <ExactNavLink to="/contact/" href="#" className="">
                  Contact
                </ExactNavLink>
              </li>
            </ul>
          </Fade>
          <div className="bg-blues-light-blue border-t-[1px] border-neutral-white pb-[40px] px-[32px] pt-[16px]">
            <div className="flex flex-row mb-4">
              <p className="typo-footer text-base text-blues-light font-bold">
                A.&nbsp;
              </p>
              <p className="typo-footer text-base text-blues-light">
                <a target="_blank" href="https://goo.gl/maps/eGgrz2zksmtG5Y4GA">
                  273 Ponce de León Avenue, Hato Rey
                </a>
              </p>
            </div>
            <div className="flex flex-row">
              <p className="typo-footer text-base text-blues-light font-bold">
                T.&nbsp;
              </p>
              <p className="typo-footer text-base text-blues-light">
                <a href="tel:787-622-9600">787 622 9600</a>
              </p>
            </div>
          </div>
        </div>
      </Slide>
    </>
  );
};

export default Navbar;
