import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import Footer from "./Footer";
import Navbar from "./Navbar";
import "../css/index.scss";
import FooterDesktop from "./FooterDesktop";
import config from "react-reveal/globals";

config({ ssrFadeout: true });

const Layout = ({ children }) => {
  const breakpoints = useBreakpoint();

  return (
    <div className="relative">
      <div className="menu-secure-layer bg-blues-light-blue absolute z-[999] h-full w-full"></div>
      <Navbar />
      <main>{children}</main>
      {breakpoints.sm ? <Footer /> : <FooterDesktop />}
    </div>
  );
};

export default Layout;
