import { useEffect, useState } from "react";
import { useIsBrowser } from ".";

export function useScrollTop() {
  const [scrollTop, setScrollTop] = useState(0);
  const isBrowser = useIsBrowser();

  useEffect(() => {
    if (isBrowser) {
      handleScroll();
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      }
    }
  }, [isBrowser])


  const handleScroll = () => {
    setScrollTop(window.scrollY);
  }

  return scrollTop;
}