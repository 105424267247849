import { useEffect, useState } from "react";
import { useIsBrowser } from ".";

export function useHaveScroll() {
  const [haveScroll, setHaveScroll] = useState(0);
  const isBrowser = useIsBrowser();

  useEffect(() => {
    if (isBrowser) {
      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      }
    }
  }, [isBrowser])


  const handleResize = () => {
    setHaveScroll(window.innerHeight <= document.documentElement.offsetHeight);
  }

  return haveScroll;
}