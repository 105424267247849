import React from "react";
import { Container1440Responsive } from "./common/global.styled";
import FooterContact from "./FooterContact";
import data from "../data/footer.json";
import Logo from "./common/logo/Logo";

const FooterDesktop = () => {
  return (
    <div className="w-full">
      <Container1440Responsive className="py-6 md:pt-12 md:pb-[120px]">
        <div className="w-full grid grid-cols-4 gap-x-20 gap-y-8">
          <div className="">
            <Logo width="37" height="33" className="mr-2" />
          </div>
          <div>
            <p className="typo-footer-title mb-4 text-blues-light-blue">
              CONTACT
            </p>
            <FooterContact {...data.contact[0]} />
            <FooterContact {...data.contact[1]} />
            <br />
            <p className="typo-footer-title mb-4 text-blues-light-blue">
              FOR TENANT
            </p>
            <a
              className="typo-footer text-neutral-grey cursor-pointer hover:text-blues-light-blue transition duration-75"
              href="https://commercialcenters.com/request/"
              target="_blank"
            >
              Service Request
            </a>
          </div>

          <div>
            <p className="typo-footer-title mb-4 text-blues-light-blue">
              OPERATION HOURS
            </p>
            <p className="typo-footer font-bold text-blues-dark-blue">
              Monday through Friday
            </p>
            <p className="typo-footer text-neutral-grey">
              6:00 a.m .- 6:00 p.m.
            </p>
            <p className="typo-footer text-neutral-grey">
              6:00 p.m .- 6:00 a.m. (Controlled Access)
            </p>
            <br />
            <p className="typo-footer font-bold text-blues-dark-blue">
              Saturdays
            </p>
            <p className="typo-footer text-neutral-grey">
              7:00 a.m. - 4:00 p.m.
            </p>
          </div>

          <div>
            <p className="typo-footer-title mb-4 text-blues-light-blue">
              FOR LEASING
            </p>
            <FooterContact {...data.leasing[0]} />
            {/* <FooterContact {...data.leasing[1]} /> */}
            <FooterContact {...data.leasing[2]} />
            <FooterContact {...data.leasing[3]} />
          </div>
        </div>
      </Container1440Responsive>
    </div>
  );
};

export default FooterDesktop;
